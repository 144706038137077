@import url(https://use.typekit.net/toe3wsy.css);
@import url(https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lightgallery.css);
@import url(https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-zoom.css);
@import url(https://cdn.jsdelivr.net/npm/lightgallery@2.4.0/css/lg-video.css);
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: white;
  font-family: din-2014, sans-serif;
  font-weight: 400;
  font-style: normal;
}
a {
  text-decoration: none;
  color: white;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

section {
  position: absolute;
  top: -50px;
  left: -50px;
  width: 120%;
  height: 120%;
  background: repeating-linear-gradient(
    #111,
    rgb(88 83 83) 80%,
    rgb(0, 0, 0) 80%,
    rgb(0, 0, 0)
  );
  background-size: 5px 5px;
  -webkit-filter: url(#noise);
          filter: url(#noise);
}
svg {
  width: 0;
  height: 0;
}

ul#menu li {
  display: inline;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
}

ul#menu li a {
  color: #7d7d7d;
}

ul#menu li a:hover {
  color: #fff;
}

.active {
  color: #fff !important;
}

.active:hover {
  color: #7d7d7d;
}

#menu {
  margin: 0px;
  padding: 0px;
}

.menucontainer {
  /* position: absolute;
  background: transparent;
  color: white;
  letter-spacing: 5px;
  transform: rotate(270deg);
  font-size: 18px;
  z-index: 999; */
  width: 100vh;
  height: 60px;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  position: absolute;
  left: 98px;
  bottom: 0;
  text-align: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  z-index: 2;
  font-size: 23px;
  letter-spacing: 5px;
}

#leva__root {
  display: none !important;
}

/* font-family: din-2014,sans-serif;
font-weight: 400;
font-style: italic;


font-family: din-2014,sans-serif;
font-weight: 400;
font-style: normal;

font-family: din-2014,sans-serif;
font-weight: 700;
font-style: italic;

font-family: din-2014-narrow,sans-serif;
font-weight: 400;
font-style: normal; */

.siteContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: 1;
}

.homeCompanyLogo {
  position: absolute;
  width: 450px;
  height: 326px;
}

.companyLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.site-background-noise {
  background-image: url(/static/media/noise.1007367b.gif);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  background-color: black;
  z-index: 0;
  opacity: 0.2; /* Opacity for Modern Browsers */
  filter: alpha(opacity=20); /* Opacity for IE8 and lower */
  background-size: 479px 142px;
}

.page-company-logo {
  position: absolute;
  left: 63px;
  top: 45px;
  width: auto;
  height: auto;
  background-color: transparent;
  z-index: 999;
}
.page-company-name {
  position: absolute;
  left: 200px;
  top: 66px;
  width: auto;
  height: auto;
  background-color: transparent;
  z-index: 999;
}

.aboutUsContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
}

.aboutUsContent {
  width: 70%;
  font-size: 36px;
  margin-top: -10%;
  font-weight: 800;
}

.aboutus-company {
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutus-close-button {
  position: absolute;
  left: 78px;
  top: 50px;
  width: auto;
  height: auto;
  background-color: transparent;
  z-index: 999;
  visibility: visible;
}

.contactUsContent {
  width: 70%;
  font-size: 36px;
  font-weight: 800;
}
.contactus-map {
  width: 100%;
  height: 400px;
  background-color: black;
  margin-top: 100px;
}

.contactUsContentContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  flex-direction: column;
}

.comapnylogo-map {
  margin-top: -47px;
  margin-left: -19px;
}

.work-items {
  text-decoration: none;
  display: inline;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 90px;
}

ul#work-items li {
  display: block;
  font-size: 5vw;
  font-weight: 800;
  padding-bottom: 0px;
  margin: 0px;
  height: auto;
}

ul#work-items li a {
  color: #7d7d7d;
}
ul#work-items li a:hover {
  color: #fff;
}

.work-category-items {
  text-decoration: none;
  display: inline;
  padding: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 90px;
}

ul#work-category-items li {
  display: block;
  font-size: 10vh;
  font-weight: 800;
  padding-bottom: 0px;
  margin: 0px;
  height: auto;
}

ul#work-category-items li a {
  color: #7d7d7d;
}
ul#work-category-items li a:hover {
  color: #fff;
}

.work-category-name {
  position: absolute;
  left: 200px;
  top: 66px;
  width: auto;
  height: auto;
  background-color: transparent;
  z-index: 2;
  font-size: 30px;
}

.lg-react-element {
  display: flex !important;
  flex-wrap: wrap !important;
}

.lg-react-element > a {
  /* background-color: #f1f1f1;  */
  width: 440px;
  height: 440px;
  margin: 10px;
  text-align: left;
  font-size: 30px;
}

.lg-react-element > div {
  /* background-color: #f1f1f1;  */
  width: 440px;
  height: 440px;
  margin: 10px;
  text-align: left;
  font-size: 30px;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-container > div {
  background-color: #f1f1f1;
  width: 440px;
  height: 440px;
  margin: 10px;
  text-align: left;
  font-size: 30px;
}

.myworkDetail-container {
  position: absolute;
  left: 140px;
  padding-top: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

/* .mywork-container {
  position: absolute;
  left: 140px;
  padding-top: 12%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  width: 100%;
} */

.mywork-container {
  width: 100%;
  height: auto;
  padding-left: 140px;
  margin-top: 100px;
}

.mywork-category-container {
  position: absolute;
  left: 10%;
  padding-top: 100px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  width: 90%;
}

img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

@media only screen and (max-width: 1200px) {
  /*Tablets [601px -> 1200px]*/
  .homeCompanyLogo {
    position: absolute;
    width: 400px;
    height: 291px;
  }
}
@media only screen and (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/

  




  .homeCompanyLogo {
    position: absolute;
    width: 300px;
    height: 217px;
  }

  .mywork-category-container {
    position: absolute;
    left: 0px;
    padding-top: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
  }

  .page-company-logo {
    position: absolute;
    left: 20px;
    top: 20px;
    width: auto;
    height: auto;
    z-index: 999;
  }

  .page-company-logo img {
    width: 80px;
  }

  .menucontainer {
    display: none;
  }

  .work-category-name {
    position: absolute;
    left: 23px;
    top: 75px;
    width: auto;
    height: auto;
    background-color: transparent;
    z-index: 2;
    font-size: 30px;
  }
  .page-company-name {
    position: absolute;
    left: 114px;
    top: 26px;
    width: auto;
    height: auto;
    background-color: transparent;
    z-index: 2;
    font-size: 30px;
    /* position: absolute;
    left: 200px;
    top: 66px;
    width: auto;
    height: auto;
    background-color: transparent;
    z-index: 999; */
  }
  .page-company-name img {
    width: 150px;
  }
  .mywork-container {
    width: 100%;
    height: auto;
    padding-left: 0%;
    margin-top: 100px;
  }
  ul#work-items li {
    font-size: 6vw;
    line-height: auto;
  }
  ul#work-category-items li {
    font-size: 12vw;
    line-height: auto;
  }
  .myworkDetail-container {
    position: absolute;
    left: 0px;
    padding-top: 200px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    margin: auto;
  }


  
  /* Position and sizing of burger button */


}
@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
  .homeCompanyLogo {
    position: absolute;
    width: 250px;
    height: 181px;
  }
}
/* @media only screen and (max-height: 959px) {
  ul#work-items li {
    font-size: 60px;
  }
}
@media only screen and (max-height: 760px) {
  ul#work-items li {
    font-size: 50px;
  }
} */











.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Position and sizing of burger button */


/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #bebebebe;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top:0px
}

/* General sidebar styles */
.bm-menu {
  background: #000000;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  padding-top: 10px;
  font-size: 28px;
  font-family: din-2014, sans-serif;
  font-weight: 800;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}




.main-mobile-menu{
  visibility:hidden;

}

.aboutus-company img:nth-child(2){
   margin-left: 40px;
 }


@media (max-width: 600px) {
  /*Big smartphones [426px -> 600px]*/
  .main-mobile-menu{
    visibility:visible;

  }
  .aboutus-close-button{
    visibility:hidden;
  }

  .aboutUsContent {
    width: 70%;
    font-size: 20px;
    margin-top: -10%;
    text-align: center;
    height: auto;

  }
  .aboutUsContent img{
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .aboutus-company{
    display: inline;
  }
  .aboutus-company img:nth-child(2){
  margin-left: auto;
   margin-top: 10px;
  }
  .contactUsContent{
    font-size: 18px;
  }

  .lg-react-element > a {
    /* background-color: #f1f1f1;  */
    width: 100%;
    height: 240px;
    margin: 10px;
    text-align: left;
    font-size: 30px;
  }
  .lg-react-element img {
    width: 100%;
  }
}
